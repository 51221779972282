function daArticlePath(article) {
  const { slug, demandAcademySlug } = article

  // TODO: This shouldn't be necessary if DA title is set
  // and the backend function is generating slugs for
  // it as expected
  return `/academy/${demandAcademySlug || slug}`
}

function daArticleTitle(article) {
  const { title, demandAcademyTitle } = article

  // TODO: This shouldn't be necessary if DA title is set
  return demandAcademyTitle || title
}

module.exports = { daArticlePath, daArticleTitle }
