import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Arrow from "images/icons/da-arrow.svg"
import { daArticlePath, daArticleTitle } from "utils/demand-academy"

const CardHeroImageSection = styled.div`
  ${tw`relative rounded-l`}

  &.mini-hero {
    ${tw`rounded-sm`}
  }
`

const CardStyles = styled.div`
  ${tw`w-full rounded flex flex-row items-stretch justify-between`}

  background: rgba(0, 0, 0, 0.03);

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .hero {
    ${tw`hidden flex-shrink-0`}

    width: 199px;
    height: 113px;
  }

  .content {
    ${tw`py-3 l:py-4 flex flex-col ml-4 mr-5 l:mr-3 relative`}

    .title-wrapper {
      ${tw`mt-2 flex flex-row items-center`}

      .mini-hero {
        ${tw`hidden mr-3 w-1/3 flex-shrink-0`}

        width: 105px;
        height: 60px;
      }

      .title {
        ${tw`text-grey-5 font-display text-sm leading-normal`}
      }
    }

    .selected-bar {
      ${tw`absolute h-full left-0 top-0 w-1 -ml-4`}

      ${({ selectedBarColor }) => `
        background-color: ${selectedBarColor};
        box-shadow: 0px 2px 10px ${selectedBarColor}99;
      `}
    }
  }

  .arrow {
    ${tw`ml-4 l:ml-6 mr-4 l:mr-6 flex-shrink-0 hidden self-center`}

    img {
      width: 10px;
      height: 20px;
      filter: drop-shadow(0px 2px 10px rgba(227, 40, 76, 0.6));
    }
  }

  &.selected {
    .content {
      .title-wrapper {
        .title {
          ${tw`font-black`}
        }
      }
    }
  }

  &.next {
    @media (min-width: 800px) {
      ${tw`w-3/4`}
    }

    .hero {
      ${tw`l:block`}
    }

    .content {
      ${tw`mr-0 ml-3`}

      .title-wrapper {
        .mini-hero {
          ${tw`block l:hidden`}
        }
      }
    }

    .arrow {
      ${tw`block`}
    }
  }
`

export default function IndexCard({
  post,
  next = false,
  selected = false,
  selectedRef,
  indexScroll,
}) {
  const { featuredImage, tier, demandAcademyTheme } = post

  const linkState = {}

  if (!next) {
    linkState.indexScroll = indexScroll
  }

  return (
    <Link
      to={daArticlePath(post)}
      className="inline-block w-full"
      state={linkState}
    >
      <CardStyles
        className={`${next ? "next" : ""} ${selected ? "selected" : ""}`}
        selectedBarColor={tier.tertiaryColor?.hex}
        ref={selected ? selectedRef : null}
      >
        <CardHeroImageSection className="hero">
          <GatsbyImage
            image={getImage(featuredImage)}
            objectFit="cover"
            alt=""
            className="absolute top-0 left-0 w-full h-full"
            placeholder="blurred"
          />
        </CardHeroImageSection>
        <div className="content">
          {selected && <div className="selected-bar" />}
          <div
            className="font-extrabold uppercase text-xs"
            style={{ color: tier.secondaryColor?.hex }}
          >
            {tier.name}
          </div>
          <div className="text-grey-35 text-xs">{demandAcademyTheme}</div>
          <div className="title-wrapper">
            <CardHeroImageSection className="mini-hero">
              <GatsbyImage
                image={getImage(featuredImage)}
                objectFit="cover"
                alt=""
                className="absolute top-0 left-0 w-full h-full"
                placeholder="blurred"
              />
            </CardHeroImageSection>
            <div className="title">{daArticleTitle(post)}</div>
          </div>
        </div>
        <div className="arrow">
          <img src={Arrow} alt="" />
        </div>
      </CardStyles>
    </Link>
  )
}
