const monitor = new RegExp(/TV360\s*monitor/, "giu")
const enterprise = new RegExp(/TV360\s*enterprise/, "giu")
const tv360 = new RegExp(/TV\s*360\s/, "giu")

exports.brandRules = (content, isHtml = true) => {
  if (!content) {
    return content
  }
  if (isHtml) {
    return content
      .replace(
        monitor,
        `<span class="demand360-brand">DEMAND360<sup>LITE</sup></span>`
      )
      .replace(enterprise, `<span class="demand360-brand">DEMAND360</span>`)
      .replace(tv360, `<span class="demand360-brand">DEMAND360</span> `)
  } else {
    return content
      .replace(monitor, `DEMAND360 LITE`)
      .replace(enterprise, `DEMAND360`)
      .replace(tv360, "DEMAND360 ")
  }
}
