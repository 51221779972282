import React, { forwardRef } from "react"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Video from "images/icons/da-video.svg"
import Pdf from "images/icons/da-pdf.svg"
import Tag from "components/tag"
import { daArticleTitle } from "utils/demand-academy"

const HeroImageSection = styled.div`
  ${tw`relative w-full h-auto`}

  aspect-ratio: 16 / 9;

  @media (min-width: 640px) {
    aspect-ratio: auto;
    height: 436px;
  }

  @media (min-width: 1200px) {
    ${tw`h-auto`}

    aspect-ratio: 16 / 9;
  }
`

const BasicInfoStyles = styled.div`
  ${tw`w-full flex flex-col relative`}

  z-index: 4;

  @media (max-width: 1200px) {
    background: #f7f7f7;
  }

  .hero-action {
    ${tw`pointer-events-none`}

    &.enabled {
      ${tw`pointer-events-auto cursor-pointer`}
    }
  }

  .hero {
    ${tw`relative`}

    .circle {
      ${tw`rounded-full absolute`}

      background: #131313;
      width: 80px;
      height: 80px;
      left: calc(50% - 40px);
      top: calc(50% - 40px);

      img {
        ${tw`absolute`}
      }

      &.video {
        img {
          width: 35px;
          height: 39px;
          left: calc(50% - 13px);
          top: calc(50% - 20px);

          filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));
        }
      }

      &.pdf {
        img {
          width: 28px;
          height: 36px;
          left: calc(50% - 13px);
          top: calc(50% - 20px);

          filter: drop-shadow(0px 2.8px 14px rgba(255, 255, 255, 0.7))
            drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }

  .meta {
    ${tw`flex flex-col l:flex-row items-start l:items-center mt-4 px-4 m:px-6 l:px-0`}

    .theme {
      ${tw`mt-2 l:mt-0 l:ml-3 font-display text-sm`}

      font-size: 13px;
    }
  }

  h3 {
    ${tw`mt-4 px-4 m:px-6 l:px-0 font-display text-2xl leading-tight`}

    color: #0C0C0C;

    @media (min-width: 768px) {
      font-size: 27px;
    }

    @media (min-width: 1200px) {
      font-size: 32px;
    }
  }
`

function BasicInfoComponent({ post, type, onClick, className = "" }, ref) {
  const { featuredImage, demandAcademyTier, demandAcademyTheme } = post

  const tierBoxShadow = demandAcademyTier.tertiaryColor?.hex
    ? `0px 2px 10px ${demandAcademyTier.tertiaryColor.hex}99`
    : ""

  const actionsEnabled = type === "pdf" || type === "video"

  const actions = {}

  if (actionsEnabled) {
    actions.onClick = handleClick
  }

  function handleClick(event) {
    if (event) {
      event.preventDefault()
    }

    onClick()
  }

  return (
    <BasicInfoStyles className={className} ref={ref}>
      {featuredImage && (
        <a
          className={`hero-action ${actionsEnabled ? "enabled" : ""}`}
          {...actions}
        >
          <HeroImageSection
            className="hero"
          >
            <GatsbyImage
              image={getImage(featuredImage)}
              objectFit="cover"
              alt=""
              className="absolute top-0 left-0 w-full h-full"
              placeholder="blurred"
            />
            {actionsEnabled && (
              <div className={`circle ${type}`}>
                <img src={type === "video" ? Video : Pdf} alt="" />
              </div>
            )}
          </HeroImageSection>
        </a>
      )}
      <div className="meta">
        <Tag
          className="tag"
          background={demandAcademyTier.labelGradient}
          boxShadow={tierBoxShadow}
        >
          {demandAcademyTier.name}
        </Tag>
        <p
          className="theme small"
          style={{ color: demandAcademyTier.secondaryColor?.hex }}
        >
          {demandAcademyTheme}
        </p>
      </div>
      <h3>{daArticleTitle(post)}</h3>
    </BasicInfoStyles>
  )
}

const BasicInfo = forwardRef(BasicInfoComponent)

export default BasicInfo
