import React from "react"
import tw, { styled } from "twin.macro"
import { AnimatePresence, motion } from "framer-motion"

const SectionsMenuStyles = styled.div`
  ${tw`l:hidden -mt-12 mb-8 sticky left-0 text-grey-5`}

  padding-top: 78px;
  // This can be uncommented to enable the transition between
  // main and sticky titles on mobile
  /* top: 50px; */
  top: -14px;

  @media (min-width: 640px) {
  // This can be uncommented to enable the transition between
  // main and sticky titles on mobile
    /* top: 82px; */
    top: 18px;
  }

  z-index: 3;
  background: #f7f7f7;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(0, 0, 0, 0.04), 0px 8px 8px rgba(0, 0, 0, 0.04),
    0px 16px 16px rgba(0, 0, 0, 0.04);

  .items {
    ${tw`flex flex-row items-center`}

    .item {
      ${tw`w-1/2 text-center pb-3 uppercase text-sm relative`}

      &.selected {
        ${tw`pointer-events-none font-extrabold`}
      }

      .selected-outline {
        ${tw`absolute w-full h-full border-b-2 border-b-neonred`}
      }
    }
  }
`

const sectionMenuOpts = [
  {
    key: "content",
    title: "Learning outcomes",
  },
  {
    key: "index",
    title: "Explore",
  },
]

export default function SectionsMenu({ section, setSection, title }) {
  function switchTo(sectionKey) {
    setSection(sectionKey)
  }

  return (
    <SectionsMenuStyles>
      <AnimatePresence>
        {title && (
          <motion.div
            key="sections-menu-title"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute w-full top-0 pt-4 pb-5"
          >
            <p className="font-bold text-base px-4 whitespace-nowrap overflow-hidden overflow-ellipsis">
              {title}
            </p>
            <hr className="border-grey-83 mt-4" />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="items">
        {sectionMenuOpts.map(({ key, title }) => (
          <div
            key={key}
            className={`item ${section === key ? "selected" : ""}`}
            onClick={() => switchTo(key)}
          >
            <AnimatePresence>
              {section === key && (
                <motion.div
                  key={`outline-layout-${key}`}
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="selected-outline"
                />
              )}
            </AnimatePresence>
            {title}
          </div>
        ))}
      </div>
    </SectionsMenuStyles>
  )
}
