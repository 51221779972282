import React, { useState, useRef, useEffect, useContext } from "react"
import tw, { styled } from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import { useMediaQuery } from "react-responsive"
import { useLocation } from "@reach/router"

import Video from "images/icons/da-video.svg"
import Pdf from "images/icons/da-pdf.svg"
import Open from "images/icons/da-open.svg"
import ButtonLE from "components/button-le"
import Button from "components/button"
import Modal from "components/modal"
import YouTube from "components/youtube.js"
import { brandRules } from "utils/brand-node"
import { daArticleTitle } from "utils/demand-academy"
import { Main } from "components/post"
import StickyHeaderContext from "context/sticky-header-context"
import BasicInfo from "components/demand-academy/basic-info"
import IndexCard from "components/demand-academy/index-card"
import SectionsMenu from "components/demand-academy/sections-menu"

const Styles = styled.div`
  padding-top: 50px;

  @media (min-width: 640px) {
    padding-top: 80px;
  }

  @media (min-width: 1200px) {
    padding-top: 130px;
  }

  .main-wrapper {
    ${tw`bg-white`}

    .basic-info {
      ${tw`l:hidden opacity-100 visible`}

      &.hide {
        ${tw`opacity-0 invisible`}
      }
    }

    .main {
      ${tw`mx-auto l:max-w-l flex flex-col l:flex-row pb-32 l:pb-0 l:space-x-8`}

      @keyframes become-visible {
        0% {
          display: none;
          opacity: 0;
        }
        1% {
          display: block;
          opacity: 0;
        }
        100% {
          display: block;
          opacity: 1;
        }
      }

      & > .content {
        ${tw`l:w-2/3 l:pt-10 px-4 m:px-6 l:px-0 hidden l:block relative`}

        @media (min-width: 1200px) {
          padding-bottom: 114px;
        }

        &.selected {
          @media (max-width: 1200px) {
            ${tw`block`}

            animation: become-visible 0.5s ease-out;
          }
        }

        .cta {
          ${tw`mt-12`}

          &.button {
            .button-content {
              ${tw`flex flex-row items-center justify-center`}

              img {
                ${tw`mr-3`}
              }

              &.video {
                img {
                  width: 19px;
                  height: 22px;
                }
              }

              &.pdf {
                img {
                  width: 19px;
                  height: 24px;
                }
              }
            }
          }

          .notice {
            ${tw`italic text-xs`}

            color: #878787;
          }
        }

        .explore-next {
          & > .title {
            ${tw`mb-3 text-sm font-bold uppercase`}

            color: #878787;
          }
        }
      }

      .index-wrapper {
        ${tw`l:w-1/3 hidden l:block px-4 m:px-6 l:px-0 l:sticky relative`}

        @media (min-width: 1200px) {
          top: 120px;
          left: 0px;
          height: calc(100vh - 160px);
          margin-bottom: 40px;
        }

        &.selected {
          @media (max-width: 1200px) {
            ${tw`block`}

            animation: become-visible 0.5s ease-out;
          }
        }

        &.sticky {
          @media (min-width: 1200px) {
            height: calc(100vh - 120px);
          }
        }

        .index {
          ${tw`flex flex-col l:h-full`}

          & > .title {
            ${tw`hidden l:block pt-10 mb-4 text-grey-5 text-sm uppercase font-bold`}
          }

          .scroll-wrapper {
            ${tw`h-full l:flex-grow l:flex-shrink l:relative l:overflow-hidden`}

            flex-basis: 0;

            .items {
              ${tw`space-y-1 l:overflow-y-auto l:h-full`}
            }

            .gradient {
              ${tw`absolute pointer-events-none w-full visible opacity-100`}

              height: 40px;
              left: 0px;
              transition: all 300ms;
              z-index: 1;

              &.top {
                ${tw`top-0`}

                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
              }

              &.bottom {
                ${tw`bottom-0`}

                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
              }

              &.hidden {
                ${tw`hidden opacity-0`}
              }

              @media (max-width: 1200px) {
                ${tw`hidden opacity-0`}
              }
            }
          }
        }
      }
    }

    .load-more {
      ${tw`py-4 rounded font-bold uppercase text-grey-35 w-full text-center cursor-pointer`}

      background: rgba(0, 0, 0, 0.05);

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.05),
            rgba(0, 0, 0, 0.05)
          ),
          rgba(0, 0, 0, 0.05);
      }
    }
  }
`

function getType(post, intercomArticle) {
  const { files, externalUrl, youtubeId, body, wordpressId, legacyBody } = post

  if (intercomArticle?.body) {
    return "intercomArticle"
  } else if (youtubeId) {
    return "video"
  } else if (files.length > 0) {
    // Guaranteed to be a pdf file because of the query
    return "pdf"
  } else if (externalUrl) {
    return "link"
  } else if ((wordpressId && legacyBody) || body?.html) {
    return "article"
  } else {
    return "unknown"
  }
}

const Content = styled.div`
  ${tw`font-body text-grey-10`}

  @media (max-width: 1200px) {
    ${tw`mx-auto`}

    max-width: 800px;
  }

  &.body-wrapper {
    ${tw`overflow-hidden relative`}

    .body {
      max-height: 300vh;

      &.show-all {
        ${tw`overflow-visible`}

        max-height: none;
      }

      &.has-more {
        ${tw`transition-all duration-1000 ease-out`}

        &::before {
          ${tw`absolute bottom-0 left-0 w-full`}

          content: "";
          height: 210px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 8.45%,
            #ffffff 100%
          );
        }
      }
    }
  }

  p,
  li {
    ${tw`mb-4`}
  }

  p.blurred {
    padding-top: 20px;
    color: transparent;
    text-shadow: 0 0 13px rgba(0, 0, 0, 0.5);
    user-select: none;
  }

  a {
    ${tw`text-dodgeblue`}
    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    ${tw`pl-8 list-decimal`}
  }

  ul {
    ${tw`pl-8 list-disc`}
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    ${tw`text-grey-15`}
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }

  h1,
  h5 {
    margin-bottom: 32px;
    margin-top: 80px;
  }

  h2 {
    margin-bottom: 21px;
    margin-top: 60px;
  }

  h3 {
    margin-bottom: 10px;
    margin-top: 40px;
  }

  h4 {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  b,
  strong {
    ${tw`font-display`}
  }

  img,
  img.size-full {
    width: 100%;
  }

  img[width] {
    width: auto;
    margin-left: unset;
    margin-right: unset;
  }

  .tags {
    ${tw`font-display font-bold text-grey-45`}
    font-size: 13px;
    letter-spacing: 0;
    line-height: 17px;
    text-transform: uppercase;
    white-space: normal;
    @media (min-width: 640px) {
      font-size: 19px;
    }

    .tags-label {
      height: 30px;
      display: inline-block;
      padding-right: 0.5rem;
      padding-top: 2px;
      vertical-align: bottom;
    }
  }

  .hr-above {
    ${tw`border-t border-grey-65 pt-12 mt-12`}
  }

  iframe {
    ${tw`max-w-full`}
  }
`

export default function DemandAcademyPost({
  post,
  intercomArticle,
  indexScroll: prevIndexScroll,
}) {
  const data = useStaticQuery(graphql`
    query DemandAcademyPost {
      gcms {
        demandAcademyTiers(orderBy: order_ASC) {
          id
          name
          color {
            css
            hex
          }
          secondaryColor {
            css
            hex
          }
          tertiaryColor {
            css
            hex
          }
          insightsArticles {
            id
            slug
            title
            demandAcademySlug
            demandAcademyTitle
            demandAcademyTheme
            featuredImage {
              altText
              url
              base64
              mimeType
              height
              width
              handle
              gatsbyImageData(width: 1200, placeholder: "blurred")
            }
          }
        }
      }
    }
  `)

  const [modal, setModal] = useState(false)
  const [section, setSection] = useState("content")
  const [contentOffset, setContentOffset] = useState(0)
  const [hasMoreContent, setHasMoreContent] = useState(false)
  const [showAllContent, setShowAllContent] = useState(false)
  const [indexScroll, setIndexScroll] = useState(0)
  const [atTop, setAtTop] = useState(true)
  const [atBottom, setAtBottom] = useState(true)

  const location = useLocation()

  const indexRef = useRef()
  const selectedRef = useRef()
  const bodyRef = useRef()
  const pdfLinkRef = useRef()

  const [basicInfoRef, , entry] = useInView({
    threshold: [0, 0.2],
  })

  // This can be uncommented to enable the transition between
  // main and sticky titles on mobile
  // const showSecondaryTitle = entry?.intersectionRatio <= 0.2
  const showSecondaryTitle = false

  const isTablet = useMediaQuery({ minWidth: 640 })
  const isDesktop = useMediaQuery({ minWidth: 1200 })

  const { isStuck } = useContext(StickyHeaderContext)

  const { demandAcademyTiers: tiers } = data.gcms
  const {
    id,
    body,
    legacyBody,
    wordpressId,
    demandAcademySummary,
    externalUrl,
    youtubeId,
    files,
  } = post

  const type = getType(post, intercomArticle)

  const pdfUrl = files?.[0]?.url

  const articleBody =
    intercomArticle?.body || (wordpressId && legacyBody) || body?.html

  const postsIndex = buildPostsIndex(tiers)
  const nextPost = getNextPost(id, postsIndex)

  useEffect(() => {
    if (isDesktop) {
      if (prevIndexScroll !== undefined) {
        indexRef.current?.scrollTo({
          top: prevIndexScroll,
        })
      } else {
        scrollToCurrent()
      }
    }
  }, [])

  useEffect(() => {
    if (section === "index") {
      setContentOffset(window.scrollY)
      scrollToCurrent()
    } else {
      scrollToContent()
    }
  }, [section])

  useEffect(() => {
    if (bodyRef.current) {
      const { scrollHeight, clientHeight, parentElement } = bodyRef.current
      setHasMoreContent(
        scrollHeight > clientHeight || parentElement.clientHeight > clientHeight
      )
    }
  }, [])

  useEffect(() => {
    const { current } = indexRef

    current?.addEventListener("scroll", handleIndexScroll)

    return () => current?.removeEventListener("scroll", handleIndexScroll)
  }, [])

  useEffect(() => {
    // Auto open modal if #view or #play. Only open pdf on desktop because
    // mobile has some problems with the modal, video is ok for both device types.
    const openPdf = location?.hash === "#view" && type === "pdf" && isDesktop
    const playVideo = location?.hash === "#play" && type === "video"

    if (openPdf || playVideo) {
      setModal(true)
    }
  }, [location])

  function handleIndexScroll() {
    setIndexScroll(indexRef.current.scrollTop)

    const { scrollHeight, scrollTop, offsetHeight } = indexRef.current

    setAtTop(scrollTop === 0)
    setAtBottom(scrollHeight <= scrollTop + offsetHeight)
  }

  function doAction() {
    if (!isDesktop && type === "pdf") {
      // To avoid limitations when opening pdf files in an iframe on iOS
      pdfLinkRef.current?.click()
    } else {
      setModal(true)
    }
  }

  function buildPostsIndex(tiers) {
    return tiers.reduce((prev, tier) => {
      const tierPosts = tier.insightsArticles.map((post) => ({
        ...post,
        tier,
      }))

      return prev.concat(tierPosts)
    }, [])
  }

  function getNextPost(postId, postsIndex) {
    const currPostIndex = postsIndex.findIndex(({ id }) => id === postId)
    return postsIndex[currPostIndex + 1]
  }

  function scrollToCurrent() {
    const indexEl = indexRef.current
    const selectedEl = selectedRef.current
    if (indexEl && selectedEl) {
      if (isDesktop) {
        // This is for desktop, index scroll is overflown and is
        // the parent of the selected element
        indexEl.scrollTo({
          top: selectedEl.offsetTop,
        })
      } else if (section === "index") {
        const elemRect = selectedEl.getBoundingClientRect()
        // This can be uncommented to enable the transition between
        // main and sticky titles on mobile
        // let diff = 180
        let diff = 120
        if (isTablet) {
          diff += window.scrollY > 0 ? 35 : 20
        }
        // The parent of the selected element is the viewport
        window.scrollTo({
          top: window.scrollY + elemRect.top - diff,
        })
      }
    }
  }

  function scrollToContent() {
    window.scrollTo({
      top: contentOffset,
    })
  }

  function showAll() {
    setShowAllContent(true)
    setHasMoreContent(false)
  }

  return (
    <Styles>
      {(type === "video" || type === "pdf") && modal && (
        <Modal isVisible={modal} onClose={() => setModal(false)}>
          <div className="video-player h-full w-full m:px-16">
            {type === "video" && (
              <YouTube
                videoId={youtubeId}
                active={true}
                autoplay={true}
                fixAspectRatio={false}
              />
            )}
            {type === "pdf" && (
              <iframe src={pdfUrl} className="w-full h-full" />
            )}
          </div>
        </Modal>
      )}
      {type === "pdf" && (
        <a
          href={pdfUrl}
          rel="noopener noreferrer"
          target="_blank"
          ref={pdfLinkRef}
          className="hidden"
        >
          Open pdf on new tab
        </a>
      )}
      <div className="main-wrapper">
        <div className={`basic-info ${showSecondaryTitle ? "hide" : ""}`}>
          <BasicInfo
            post={post}
            type={type}
            onClick={doAction}
            ref={basicInfoRef}
          />
        </div>
        <div className="main">
          <SectionsMenu
            section={section}
            setSection={setSection}
            title={showSecondaryTitle ? daArticleTitle(post) : null}
          />
          <div className={`content ${section === "content" ? "selected" : ""}`}>
            <div className="hidden l:block mb-12">
              <BasicInfo post={post} type={type} onClick={doAction} />
            </div>
            <Main>
              {demandAcademySummary?.html && (
                <Content
                  className="summary typetura-article"
                  dangerouslySetInnerHTML={{
                    __html: brandRules(demandAcademySummary.html),
                  }}
                />
              )}
              {demandAcademySummary?.html &&
                (type === "article" || type === "intercomArticle") && (
                  <hr className="border-grey-65 mt-12 mb-12" />
                )}
              {(type === "article" || type === "intercomArticle") && (
                <>
                  <Content className="body-wrapper typetura-article">
                    <div
                      className={`body ${showAllContent ? "show-all" : ""}
                          ${hasMoreContent ? "has-more" : ""}
                        `}
                      dangerouslySetInnerHTML={{
                        __html: brandRules(articleBody),
                      }}
                      ref={bodyRef}
                    />
                  </Content>
                  {hasMoreContent && (
                    <div onClick={() => showAll()} className="load-more mt-11">
                      Load full article
                    </div>
                  )}
                </>
              )}
            </Main>
            {type === "link" && (
              <div className="cta">
                <ButtonLE color="red" href={externalUrl} target="_blank">
                  <div className="flex flex-row items-center justify-center text-grey-35">
                    Read Full Article
                    <img className="ml-3 -mt-1" src={Open} alt="" />
                  </div>
                </ButtonLE>
                <span className="notice">
                  This article may require a subscription.
                </span>
              </div>
            )}
            {(type === "pdf" || type === "video") && (
              <div className="cta button">
                <Button className="red" onClick={doAction}>
                  <div className={`button-content ${type}`}>
                    <img src={type === "pdf" ? Pdf : Video} alt="" />
                    {type === "pdf" ? "Open PDF" : "Watch Video"}
                  </div>
                </Button>
              </div>
            )}
            {nextPost && (
              <div className="explore-next">
                <hr className="border-grey-83 mt-12 mb-10" />
                <div className="title">Explore next</div>
                <IndexCard post={nextPost} next={true} />
              </div>
            )}
          </div>
          <div
            className={`index-wrapper ${
              section === "index" ? "selected" : ""
            } ${isStuck ? "sticky" : ""}`}
          >
            <div className="index">
              <div className="title">Explore demand Academy</div>
              <div className="scroll-wrapper">
                <div className={`gradient top ${atTop ? "hidden" : ""}`} />
                <div className="items" ref={indexRef}>
                  {postsIndex.map((post) => (
                    <IndexCard
                      key={post.id}
                      post={post}
                      selected={post.id === id}
                      selectedRef={selectedRef}
                      indexScroll={indexScroll}
                    />
                  ))}
                </div>
                <div
                  className={`gradient bottom ${atBottom ? "hidden" : ""}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  )
}
