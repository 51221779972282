import React from "react"
import { graphql } from "gatsby"
import striptags from "striptags"

import SEO from "components/seo"
import Layout from "components/layout"
import DemandAcademyPost from "components/demand-academy-post"
import { daArticlePath } from "utils/demand-academy"

export default ({ data, pageContext, location }) => {
  const { insightsArticle: post } = data.gcms
  const { intercomArticle } = pageContext

  const imageURL = post.featuredImage?.url

  const sharePreviewUrls = {
    social: imageURL,
    twitter: imageURL,
  }

  return (
    <Layout demandAcademy={true}>
      <SEO
        title={post.demandAcademyTitle || post.title}
        description={
          post.demandAcademySummary?.text ||
          post.summary?.text ||
          striptags(post.legacySummary) ||
          ""
        }
        path={daArticlePath(post)}
        sharePreviewUrls={sharePreviewUrls}
      />
      <DemandAcademyPost
        post={post}
        intercomArticle={intercomArticle}
        indexScroll={location?.state?.indexScroll}
      />
    </Layout>
  )
}

export const query = graphql`
  query AcademyPostById($id: ID!) {
    gcms {
      insightsArticle(where: { id: $id }) {
        id
        title
        body {
          html
          text
        }
        featuredImage {
          altText
          url
          base64
          mimeType
          height
          width
          handle
          gatsbyImageData(width: 1200, placeholder: "blurred")
        }
        externalUrl
        format
        files(first: 1, where: { mimeType: "application/pdf" }) {
          id
          fileName
          url
          mimeType
        }
        youtubeId
        slug
        demandAcademySlug
        demandAcademySummary {
          html
          text
        }
        demandAcademyTheme
        demandAcademyTier {
          name
          labelGradient
          color {
            hex
          }
          secondaryColor {
            hex
          }
          tertiaryColor {
            hex
          }
        }
        demandAcademyTitle
        legacyBody
        wordpressId
      }
    }
  }
`
